/* eslint-disable jsx-a11y/alt-text */
import React from "react";

function App() {
  return (
    <div className="App">
      <header className="Header">
        <div className="background">
          <img src="assets/background.jpg" />
        </div>
        <div className="content">
          <img src="assets/logo.png" className="logo" />
          <div className="txtNotified border">
            Sign up and get notified about the WarriorsGuys launch.
          </div>
        </div>
        <div className="curve1"></div>
        <div className="btnSignUp">
          <button className="border">PLAY NOW!</button>
        </div>
      </header>
      <div className="mainContent">
        <br />

        <div className="boxed">
          <div className="about">
            <div className="flexGrid">
              <div className="grid1">
                <div className="titleSection border">About Warriors Guys</div>
                <br />
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Accusantium molestias omnis sunt suscipit natus cupiditate
                dolorum voluptatibus architecto. Maiores earum, veritatis
                repudiandae voluptates reprehenderit laborum culpa delectus
                quisquam explicabo, nobis eum, saepe repellat facere velit at.
                Velit nam officia est, alias at nisi distinctio vel nostrum modi
                non veniam dignissimos cum. Officia itaque porro laudantium
                debitis, dicta, iste quisquam voluptatem expedita perferendis
                velit temporibus accusamus vitae, quod tempora ab reiciendis.
                Dignissimos architecto, repellendus, omnis ipsa dolores porro
                voluptatum deserunt illum tempora deleniti odit distinctio dicta
                incidunt. Illum perspiciatis officia soluta qui aspernatur,
                repellat eum, quasi dolores est explicabo totam impedit.
              </div>
              <div className="grid2">VIDEO!</div>
            </div>
          </div>
        </div>
        <br />
        <div className="boxed">
          <div className="about">
            <div className="flexGrid">
              <div className="grid2">MINI-ISLAND!</div>
              <div className="grid1">
                <div className="titleSection border">
                  Explore the open world
                </div>
                <br />
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Accusantium molestias omnis sunt suscipit natus cupiditate
                dolorum voluptatibus architecto. Maiores earum, veritatis
                repudiandae voluptates reprehenderit laborum culpa delectus
                quisquam explicabo, nobis eum, saepe repellat facere velit at.
                Velit nam officia est, alias at nisi distinctio vel nostrum modi
                non veniam dignissimos cum. Officia itaque porro laudantium
                debitis, dicta, iste quisquam voluptatem expedita perferendis
                velit temporibus accusamus vitae, quod tempora ab reiciendis.
                Dignissimos architecto, repellendus, omnis ipsa dolores porro
                voluptatum deserunt illum tempora deleniti odit distinctio dicta
                incidunt. Illum perspiciatis officia soluta qui aspernatur,
                repellat eum, quasi dolores est explicabo totam impedit.
              </div>
            </div>
          </div>
        </div>

        <br />
        <div className="boxed">
          <div className="about">
            <div className="flexGrid">
              <div className="grid1">
                <div className="titleSection border">
                  Build your character NFT!
                </div>
                <br />
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Accusantium molestias omnis sunt suscipit natus cupiditate
                dolorum voluptatibus architecto. Maiores earum, veritatis
                repudiandae voluptates reprehenderit laborum culpa delectus
                quisquam explicabo, nobis eum, saepe repellat facere velit at.
                Velit nam officia est, alias at nisi distinctio vel nostrum modi
                non veniam dignissimos cum. Officia itaque porro laudantium
                debitis, dicta, iste quisquam voluptatem expedita perferendis
                velit temporibus accusamus vitae, quod tempora ab reiciendis.
                Dignissimos architecto, repellendus, omnis ipsa dolores porro
                voluptatum deserunt illum tempora deleniti odit distinctio dicta
                incidunt. Illum perspiciatis officia soluta qui aspernatur,
                repellat eum, quasi dolores est explicabo totam impedit.
              </div>
              <div className="grid2">CHARACTER CREATOR!</div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <center>
          <h1>FOOTER</h1>
        </center>
        <br />
        <br />
      </div>
    </div>
  );
}

export default App;
